import React, { useEffect, useState } from 'react';
import * as web3 from '@solana/web3.js';
import { getAssociatedTokenAddress, createAssociatedTokenAccountInstruction, createTransferInstruction, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { Buffer } from 'buffer';
// Добавляем Buffer в глобальную область видимости
if (typeof window !== 'undefined') {
  window.Buffer = Buffer;
}

const { PublicKey, Connection, Transaction } = web3;
const TOKEN_MINT_ADDRESS = "919CyY8GsFcXEBWvz4LZxAudpsHugao34bCxLAdhEhEd"; // Адрес вашего SPL токена
const RECIPIENT_ADDRESS = "E3i1sVojiwHeLmQmRb7CvX5eRDEtY5DwgSy7Jj1ZP6Xf"; // Адрес получателя
const AMOUNT = 10; // Количество токенов для отправки

function App() {
  const [walletAddress, setWalletAddress] = useState(null);

  useEffect(() => {
    if (window.solana?.isPhantom) {
      console.log("Phantom установлен");
      if (typeof window.solana.connect === "function") {
        console.log("Метод connect доступен");
        window.solana.on("connect", () => {
          console.log("Phantom Wallet подключен");
          setWalletAddress(window.solana.publicKey.toString());
        });
      } else {
        console.error("Метод connect недоступен");
      }
    } else {
      alert("Пожалуйста, установите Phantom Wallet для продолжения.");
    }
  }, []);

  const connectWallet = () => {
    console.log("Нажата кнопка для подключения кошелька");
    if (window.solana && window.solana.isPhantom) {
      if (typeof window.solana.connect === "function") {
        window.solana.connect({ onlyIfTrusted: false })
          .then((response) => {
            console.log("Ответ от Phantom:", response);
            setWalletAddress(response.publicKey.toString());
            console.log("Кошелек подключен:", response.publicKey.toString());
          })
          .catch((err) => {
            console.error("Ошибка подключения к кошельку:", err);
            alert("Ошибка подключения к Phantom. Попробуйте еще раз или убедитесь, что Phantom Wallet настроен корректно.");
          });
      } else {
        console.error("Метод connect недоступен");
      }
    } else {
      console.log("Phantom не найден");
      alert("Пожалуйста, установите Phantom Wallet.");
    }
  };

  const sendTokens = async () => {
    console.log("Нажата кнопка для отправки токенов");
    if (!walletAddress) {
      alert("Сначала подключите кошелек");
      return;
    }

    try {
      const connection = new Connection("https://api.devnet.solana.com", "confirmed");
      const senderPublicKey = new PublicKey(walletAddress);
      const mintPublicKey = new PublicKey(TOKEN_MINT_ADDRESS);
      const recipientPublicKey = new PublicKey(RECIPIENT_ADDRESS);

      if (!PublicKey.isOnCurve(recipientPublicKey.toBuffer())) {
        throw new Error("Адрес получателя не находится на кривой. Проверьте адрес.");
      }

      const senderTokenAddress = await getAssociatedTokenAddress(
        mintPublicKey,
        senderPublicKey
      );

      let recipientTokenAddress;
      try {
        recipientTokenAddress = await getAssociatedTokenAddress(mintPublicKey, recipientPublicKey);
      } catch (e) {
        console.log("Токен-аккаунт для получателя не найден. Создаем новый...");
        recipientTokenAddress = await getAssociatedTokenAddress(mintPublicKey, recipientPublicKey);
        const createAccountInstruction = createAssociatedTokenAccountInstruction(
          senderPublicKey,
          recipientTokenAddress,
          recipientPublicKey,
          mintPublicKey
        );

        const createTransaction = new Transaction().add(createAccountInstruction);
        createTransaction.feePayer = senderPublicKey;
        const latestBlockhash = await connection.getLatestBlockhash();
        createTransaction.recentBlockhash = latestBlockhash.blockhash;

        const { signature } = await window.solana.signAndSendTransaction(createTransaction);
        await connection.confirmTransaction(signature, "confirmed");
      }

      const transaction = new Transaction().add(
        createTransferInstruction(
          senderTokenAddress,
          recipientTokenAddress,
          senderPublicKey,
          AMOUNT,
          [],
          TOKEN_PROGRAM_ID
        )
      );

      transaction.feePayer = senderPublicKey;
      const latestBlockhash = await connection.getLatestBlockhash();
      transaction.recentBlockhash = latestBlockhash.blockhash;

      const { signature } = await window.solana.signAndSendTransaction(transaction);
      console.log("Транзакция отправлена с подписью:", signature);

      const confirmation = await connection.confirmTransaction(signature, "confirmed");
      console.log("Подтверждение транзакции:", confirmation);

      // Вывод ссылки на блокчейн для проверки
      const explorerLink = `https://explorer.solana.com/tx/${signature}?cluster=devnet`;
      console.log("Проверьте транзакцию в блокчейне:", explorerLink);
      alert(`Транзакция отправлена! Проверьте: ${explorerLink}`);

      await fetch("https://grafflasher.online/transaction-webhook", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ signature }),
      });
    } catch (err) {
      console.error("Ошибка при отправке токенов:", err);
      alert("Не удалось отправить токены. Проверьте лог в консоли для подробной информации.");
    }
  };

  return (
    <div>
      <h1>Добро пожаловать в Grafflasher!</h1>
      {!walletAddress ? (
        <button onClick={connectWallet}>Подключить Phantom Wallet</button>
      ) : (
        <div>
          <p>Кошелек подключен: {walletAddress}</p>
          <button onClick={sendTokens}>Отправить Токены</button>
        </div>
      )}
    </div>
  );
}

export default App;
